import { lazy, Suspense, useState } from 'react';

import { Routes, Route } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Box } from '@mui/material';

import SplashScreenWrapper from './components/reusable/splashScreen/SplashScreenWrapper';
import HomePage from './pages/HomePage';
import ProfilePage from './pages/profile/ProfilePage';
import NotFound from './pages/NotFoundPage';
import { CartProvider } from './context/CartContext';
import { AlertMessageContext } from './context/AlertMessageContext';
import { LoaderProvider } from './context/LoaderContext';
import { AccountModalProvider } from './context/AccountModalContext';
import { UpgradeModalProvider } from './context/UpgradeModalContext';
import { UserSettingsProvider } from './context/UserSettingsContext';
import { checkEnv, getManifestUrl } from './utils/Utils';
import { ThemeProvider } from '@mui/material/styles';
import theme from './css/muiStyles/theme';
import './App.css';
import ToolkitUninstalled from './pages/ToolkitUninstalled';
import { CoverSheetModalProvider } from './context/CoverSheetContext';
import CoverSheetModal from './components/reusable/page/CoverSheetModal';

const AlertMessages = lazy(() => import('./components/reusable/AlertMessages'));

const App = () => {
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const manifestUrl = getManifestUrl();
    const isProduction = checkEnv();

    return (
        <HelmetProvider>
            <LoaderProvider>
                <AlertMessageContext.Provider
                    value={{
                        successMessage,
                        errorMessage,
                        setSuccessMessage,
                        setErrorMessage,
                    }}
                >
                    <AccountModalProvider>
                        <CoverSheetModalProvider>
                            <UpgradeModalProvider>
                                <ThemeProvider theme={theme}>
                                    <CartProvider>
                                        <Box>
                                            <Helmet>
                                                {isProduction && (
                                                    <meta
                                                        name="google-site-verification"
                                                        content="TtlJjOcKhd-g0A72dVz-vl2jPrndLPo6_n2WK6vhJFo"
                                                    />
                                                )}
                                                <meta
                                                    name="robots"
                                                    content={
                                                        isProduction
                                                            ? 'index, follow'
                                                            : 'noindex, nofollow'
                                                    }
                                                />
                                                {/* <link
                                                    rel="manifest"
                                                    href={manifestUrl}
                                                /> */}
                                                <meta
                                                    name="theme-color"
                                                    content="#ffffff"
                                                />
                                                <meta
                                                    name="theme-color"
                                                    content="#ffffff"
                                                />
                                                <meta
                                                    name="msapplication-navbutton-color"
                                                    content="#ffffff"
                                                />
                                                <meta
                                                    name="apple-mobile-web-app-status-bar-style"
                                                    content="white"
                                                />
                                                <link
                                                    rel="canonical"
                                                    href={
                                                        process.env
                                                            .REACT_APP_CANONICAL_URL
                                                    }
                                                />
                                            </Helmet>
                                            <SplashScreenWrapper>
                                                <UserSettingsProvider>
                                                    <Routes>
                                                        <Route
                                                            path="/*"
                                                            element={
                                                                <HomePage />
                                                            }
                                                        />
                                                        <Route
                                                            path="/user/:user_slug"
                                                            element={
                                                                <ProfilePage />
                                                            }
                                                        />
                                                        <Route
                                                            path="not-found"
                                                            element={
                                                                <NotFound />
                                                            }
                                                        />
                                                        <Route
                                                            path="/assistant-uninstall"
                                                            element={
                                                                <ToolkitUninstalled />
                                                            }
                                                        />
                                                    </Routes>

                                                    <Suspense
                                                        fallback={
                                                            <div>
                                                                Loading...
                                                            </div>
                                                        }
                                                    >
                                                        <AlertMessages />
                                                    </Suspense>

                                                    <CoverSheetModal />
                                                </UserSettingsProvider>
                                            </SplashScreenWrapper>
                                        </Box>
                                    </CartProvider>
                                </ThemeProvider>
                            </UpgradeModalProvider>
                        </CoverSheetModalProvider>
                    </AccountModalProvider>
                </AlertMessageContext.Provider>
            </LoaderProvider>
        </HelmetProvider>
    );
};

export default App;
